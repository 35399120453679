import React from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import Prductslide from './Prductslide';
import Storecart from './Storecart';



 
  
 

class Stores extends React.Component{


  constructor(props) {
    super(props);
    this.state = {
      api_url:localStorage.getItem('api_url'),
      user_id:localStorage.getItem('admin_id'),
        data:[],
        likecarts:[],
        prescription:'no',search:'',suc_cls:'',
        errors:  {},openModal : false,pic:'',fileName:'',email:'',email1:'',password:''
        
    };     
  }
 
  componentDidMount() {  
    
}
  

      render(){
        return <div className="box"> {
          <Header_home /> } <br></br>
          <br></br>
         
          

          <div className='social_container'>
            <div className='rating_detail_page'>
              <div class="rating"></div>
            </div>
          </div>
          <div class="container">
                <div className='price_amount5' >
                     
                       <Storecart/>
                <br></br>
                </div>
          </div>

          <br></br>
          <br></br>
          <div className='sell_best_product'>
            <h2 className="">Recently Viewed Products</h2>
            <Prductslide />
            <br></br>
            <br></br>
            <br></br>
          </div> {
          <Footer /> }
        </div>
      }
}

export default Stores;