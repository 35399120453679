import React from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import Menu from './Menu';
import axios from 'axios';
import Prductslide2 from './Prductslide2';
import Detail_tab from './Detail_tab';
import FavoriteCheckbox from './FavoriteCheckbox';
import Detail_rating from './Detail_rating';

class Detail_page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      product_id: localStorage.getItem('product_id'),
      user_id: localStorage.getItem('admin_id'),
      product: {},
      recentlyViewed: [],
      mainImage: '',
    };
  }

  product_detailapi() {
    axios({
      method: 'get',
      url: `${this.state.api_url}/admingetProduct_frontend5/${this.state.product_id}/${this.state.user_id}`,
    }).then(res => {
      const data = res.data;
      this.setState({
        product: data.product,
        mainImage: data.product.product_image,
      });
      localStorage.setItem("coo_product_id", this.state.product_id);
      localStorage.setItem("coo_product_name", data.product.product_name);
      localStorage.setItem("coo_product_image", data.product.product_image);
      localStorage.setItem("coo_price", data.product.price);
      localStorage.setItem("coo_quantity", data.product.qty);

      const recentlyViewed = JSON.parse(localStorage.getItem('recentlyViewed')) || [];
      const newProduct = {
        id: this.state.product_id,
        product_name: data.product.product_name,
        product_image: data.product.product_image,
        price: data.product.price
      };

      const updatedRecentlyViewed = recentlyViewed.filter(item => item.id !== this.state.product_id);
      updatedRecentlyViewed.unshift(newProduct);

      if (updatedRecentlyViewed.length > 5) {
        updatedRecentlyViewed.pop();
      }

      localStorage.setItem('recentlyViewed', JSON.stringify(updatedRecentlyViewed));
    });
  }

  componentDidMount() {
    this.product_detailapi();
  }

  handleImageClick = (image) => {
    this.setState({ mainImage: image });
  };

  render() {
    const { product, mainImage } = this.state;

    return (
      <div className="box">
        <Header_home />
        <Menu />
        <div className="short_line">
          <p className="short_text">Home / {product.cat_name} / {product.sub_cat_name} / {product.product_name}</p>
        </div>
        <h3 className='decor_page'>{product.product_name}</h3>
        <div className='social_container'>
          <div className='rating_detail_page'>
            <div className="rating">
              <Detail_rating />
            </div>
          </div>
          <div className='share_social'>
            <img className="facebook-logo1" alt="Facebook logo" src={require('./img/facebook-logo.png')} />
            <img className="twitter1" alt="Twitter" src={require('./img/twitter.png')} />
            <img className="youtube1" alt="Youtube" src={require('./img/youtube.png')} />
          </div>
        </div>
        <hr />
        <div className="container">
          <div className="image-gallery">
            
            <div className="image-thumbnails">
              {product.images && product.images.map((img, index) => (
                <img
                  key={index}
                  src={img.images || img.product_image}
                  alt={`thumbnail-${index}`}
                  onClick={() => this.handleImageClick(img.images || img.product_image)}
                  className={mainImage === (img.images || img.product_image) ? 'active' : ''}
                />
              ))}
            </div>

            <div className="main-image">
              <img src={mainImage} alt={product.product_name} />
            </div>
          </div>
          <div className='price_amount'>
            {product.discountAmount ? (
              <>
                <p className='price_a discounted-price'>₹{product.discountAmount}</p>
                <p className='price_a original-price'><s>₹{product.price}</s></p>
              </>
            ) : (
              <p className='price_a'>₹{product.price}</p>
            )}
            <p className={`price_stock ${product.qty > 0 ? 'in_stock' : 'out_stock'}`}>
              {product.qty > 0 ? 'In Stock' : 'Out of Stock'}
            </p>
            {product.offer_percentage && (
              <p className='offer_percentage'>Offer: {product.offer_percentage}% off</p>
            )}
            <hr />
            <br />
            {product.c_len === 0 ? (
              product.qty > 0 && (
                <>
                  <p className='price_detail1'>Quantity</p>
                  <FavoriteCheckbox />
                </>
              )
            ) : (
              <p>Already added</p>
            )}
            <br />
            <hr />
            <p className='price_detail1'>{product.cat_name} / {product.sub_cat_name} / {product.product_name}</p>
          </div>
        </div>
        <Detail_tab />
        <br />
        <br />
        <div className='sell_best_product'>
          <h2 className="">Recently Viewed Products</h2>
          <Prductslide2 />
          <br />
          <br />
          <br />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Detail_page;
