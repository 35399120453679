import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "./Login";
import Home from './Home';
import Product from './Product';
import Detail_page from './Detail_page';
import Addcart from './Addcart';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Likecart from './Likecart';
import Profile from './Profile';
import Popup from './Popup';
import Myorders from './Myorders';
import Trackorder from './Trackorder';
import Signup from './Signup';
import Orderplace from './Orderplace';
import DummyContent from './DummyContent';
import Gpt3Component from './Gpt3Component';
import useCustomBackNavigation from './useCustomBackNavigation';
import PrivateRoute from './PrivateRoute';
import Stores from './Stores';
import Foods from './Foods';


const App = () => {
  // useCustomBackNavigation();

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          } />
          <Route path="/product" element={
            <PrivateRoute>
              <Product />
            </PrivateRoute>
          } />
          <Route path="/detail_page" element={
            <PrivateRoute>
              <Detail_page />
            </PrivateRoute>
          } />
          <Route path="/addcart" element={
            <PrivateRoute>
              <Addcart />
            </PrivateRoute>
          } />
          <Route path="/likecart" element={
            <PrivateRoute>
              <Likecart />
            </PrivateRoute>
          } />
          <Route path="/profile" element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          } />
          <Route path="/popup" element={
            <PrivateRoute>
              <Popup />
            </PrivateRoute>
          } />
          <Route path="/myorders" element={
            <PrivateRoute>
              <Myorders />
            </PrivateRoute>
          } />
          <Route path="/trackorder" element={
            <PrivateRoute>
              <Trackorder />
            </PrivateRoute>
          } />
          <Route path="/signup" element={<Signup />} />
          <Route path="/orderplace" element={
            <PrivateRoute>
              <Orderplace />
            </PrivateRoute>
          } />
          <Route path="/gpt3component" element={
            <PrivateRoute>
              <Gpt3Component />
            </PrivateRoute>
          } />
           <Route path="/Stores" element={
            <PrivateRoute>
              <Stores />
            </PrivateRoute>
          } />
          <Route path="/Foods" element={
            <PrivateRoute>
              <Foods />
            </PrivateRoute>
          } />
          <Route path="/support-center" element={<DummyContent title="Support Center" />} />
          <Route path="/policy" element={<DummyContent title="Policy" />} />
          <Route path="/terms-conditions" element={<DummyContent title="Term & Conditions" />} />
          <Route path="/shipping" element={<DummyContent title="Shipping" />} />
          <Route path="/return" element={<DummyContent title="Return" />} />
          <Route path="/register-seller" element={<DummyContent title="Register A Seller" form />} />
          <Route path="/faqs" element={<DummyContent title="FAQs" />} />
          <Route path="/about-us" element={<DummyContent title="About Us" />} />
          <Route path="/career" element={<DummyContent title="Career" />} />
          <Route path="/contact-us" element={<DummyContent title="Contact Us" form />} />
          <Route path="/affiliate" element={<DummyContent title="Affiliate" />} />
          <Route path="/sell-on-market-place" element={<DummyContent title="Sell on Market Place" />} />
          <Route path="/advertises-on-market-place" element={<DummyContent title="Advertises on Market Place" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
