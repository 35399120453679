import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from "axios";
import { Link } from 'react-router-dom';


import './style.css';

class Footer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      user_id: localStorage.getItem('admin_id'),
      data: [],
      prescription: 'no',
      search: '',
      suc_cls: '',
      errors: {},
      openModal: false,
      name: '',
      mobile: '',
      email: '',
      partnerEmail: '', // Separate state variable for partner form email
      type: ''
    };

    this.onChange = this.onChange.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleModal5 = this.toggleModal5.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePartnerEmail = this.onChangePartnerEmail.bind(this); // Separate handler for partner email
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePartnerEmail(e) {
    this.setState({
      partnerEmail: e.target.value
    });
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  toggleModal() {
    this.setState(prevState => ({
      openModal: !prevState.openModal
    }));
  }

  toggleModal5() {
    const email = this.state.email;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email === "") {
      alert("Email Id is empty... please try with Email Id");
    } else if (!emailRegex.test(email)) {
      alert("Invalid email format. Please enter a valid email address.");
    } else {
      alert("Hi User, Thank you for subscribing to our service! We're thrilled to have you on board. Stay tuned for the latest updates, exclusive content, and special offers delivered straight to your inbox.");
      window.location.reload();
    }
  }

  async handleSubmit(e) {
    e.preventDefault();

    const { name, mobile, partnerEmail, type } = this.state;
    const errors = {};

    if (!name) errors.name = "Name is required";
    if (!mobile) errors.mobile = "Mobile number is required";
    if (!partnerEmail) errors.partnerEmail = "Email is required";
    if (!type) errors.type = "Type is required";

    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      try {
        const response = await axios.post(this.state.api_url + '/beingpatner', {
          name,
          mobile,
          email: partnerEmail,
          type
        });

        if (response.data.status) {
          alert("Hi Partner, Team will reach you shortly...");
          this.setState(prevState => ({
            openModal: !prevState.openModal,
            name: '',
            mobile: '',
            partnerEmail: '',
            type: ''
          }));
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  }

  render() {
    return (
      <div className="footer">
        <div className='row'>
        <div className='col-md-3'>
          <div className="quicklink">QUICK LINKS</div>
          <br />
          <p className="support-center">
            <Link to="/support-center">Support Center</Link><br />
            <Link to="/policy">Policy</Link><br />
            <Link to="/terms-conditions">Term &amp; Conditions</Link><br />
            <Link to="/shipping">Shipping</Link><br />
            <Link to="/return">Return</Link><br />
            <Link to="/register-seller">Register A Seller</Link><br />
            <Link to="/faqs">FAQs</Link>
          </p>
        </div>
        <div className='col-md-3'>
          <div className="company">COMPANY</div>
          <br />
          <p className="about-us-team-member">
            <Link to="/about-us">About Us</Link><br />
            <Link to="/career">Career</Link><br />
            <Link to="/contact-us">Contact Us</Link><br />
            <Link to="/affiliate">Affiliate</Link>
          </p>
        </div>
        <div className='col-md-3'>
          <div className="markplace">MARKET PLACE</div>
          <br />
          <p className="sell-on-market-place">
            <Link to="/sell-on-market-place">Sell on Market Place</Link><br />
            <Link to="/advertises-on-market-place">Advertises on Market Place</Link><br />
          </p>
        </div>
      
  
          <div className='col-md-3'>
            <div className="newsletter">NEWSLETTER</div>
            <br />
            <p className="registernow">Register now to get updates on promotions &amp; coupons</p>
            <div className='email_check'>
              <input
                className="email_here"
                type="email"
                id="email"
                placeholder="Enter your email here"
                value={this.state.email}
                onChange={this.onChangeEmail}
              />
              <button className="subscribe" onClick={this.toggleModal5}>SUBSCRIBE</button>
            </div>
            <div className="followus">Follow us</div>
            <div className='social_ions'>
              <a href="https://www.linkedin.com" target="_blank" title="Linkedin" rel="noopener noreferrer">
                <img className="linkedin" alt="Linkedin" src={require('./img/linkedin.png')} />
              </a>
              <a href="https://www.facebook.com" target="_blank" title="Facebook" rel="noopener noreferrer">
                <img className="facebook-logo" alt="Facebook logo" src={require('./img/facebook-logo.png')} />
              </a>
              <a href="https://www.twitter.com" target="_blank" title="Twitter" rel="noopener noreferrer">
                <img className="twitter" alt="Twitter" src={require('./img/twitter.png')} />
              </a>
              <a href="https://www.youtube.com" target="_blank" title="Youtube" rel="noopener noreferrer">
                <img className="youtube" alt="Youtube" src={require('./img/youtube.png')} />
              </a>
            </div>
          </div>
        </div>
        <div className=''>
          <button className='btn-patner' title="Being Partner" onClick={this.toggleModal}>Being Partner</button>
          {this.state.openModal && (
            <div className='popup-container'>
              <div className="popup">
                <div className="popup-content">
                <button className="close-btn" onClick={this.toggleModal}>&times;</button>
                  <h2>Become Our Partner</h2>
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <label className='patner_lab'>Name :</label>
                      <input
                        type="text"
                        name="name"
                        className='patner_in'
                        value={this.state.name}
                        onChange={this.onChange}
                      />
                      {this.state.errors.name && <p className="error">{this.state.errors.name}</p>}
                    </div>
                    <br></br>
                    <div className="form-group">
                      <label className='patner_lab'>Mobile :</label>
                      <input 
                        type="text"
                        name="mobile"
                        className='patner_in'
                        style={{ marginRight: '10px' }}
                        value={this.state.mobile}
                        onChange={this.onChange}
                      />
                      {this.state.errors.mobile && <p className="error">{this.state.errors.mobile}</p>}
                    </div>
                    <br></br>
                    <div className="form-group">
                      <label className='patner_lab'>Email :</label>
                      <input
                        type="email"
                        name="partnerEmail"
                        className='patner_in'
                        value={this.state.partnerEmail}
                        onChange={this.onChangePartnerEmail}
                      />
                      {this.state.errors.partnerEmail && <p className="error">{this.state.errors.partnerEmail}</p>}
                    </div>
                    <br></br>
                    <div className="form-group">
                      <label className='patner_lab'>Type :</label>
                      <select name="type" className='patner_in' value={this.state.type} onChange={this.onChange}>
                        <option value="">Select</option>
                        <option value="delivery">Delivery Partner</option>
                        <option value="store">Store Partner</option>
                      </select>
                      {this.state.errors.type && <p className="error">{this.state.errors.type}</p>}
                    </div>
                    <br></br>
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
        <hr />
        <div className='row'>
          <div className='col-md-6'>
            <div className="copyright">Copyright © 2023</div>
          </div>
          <div className='col-md-3'>
            <p className="payment-text">We Using Safe Payment For</p>
          </div>
          <div className='col-md-2'>
            <img className="payment_img" alt="" src={require('./img/image_35.png')} />
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
