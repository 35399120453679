import React, { useState, useEffect } from "react";
import './Ordercard.css'; // Import your CSS file for styling
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const Myfoods = () => {
  const [cards, setCards] = useState([]);
  const navigate = useNavigate();

  // Increase quantity of a product in the cart
  const increaseQuantity = (product_id, price) => {
    setCards(prevCards =>
      prevCards.map(card =>
        card.product_id === product_id
          ? {
              ...card,
              quantity: card.quantity + 1,
              totalAmount: (card.quantity + 1) * price
            }
          : card
      )
    );
  };

  // Decrease quantity of a product in the cart
  const decreaseQuantity = (product_id, currentQuantity, price) => {
    if (currentQuantity > 1) {
      setCards(prevCards =>
        prevCards.map(card =>
          card.product_id === product_id
            ? {
                ...card,
                quantity: card.quantity - 1,
                totalAmount: (card.quantity - 1) * price
              }
            : card
        )
      );
    } else {
      alert("Quantity must be at least 1");
    }
  };

  // Handle adding item to cart
  const handleAddToCart = async (cart_id, product_id, price, quantity) => {
    try {
      const user_id = localStorage.getItem('admin_id');
      const api_url = localStorage.getItem('api_url');
      const response = await axios.post(api_url + '/addtocart', {
        product_id,
        user_id,
        quantity,
        price,
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data.status) {
        handlelikeCartremove1(cart_id);
        navigate("/Addcart", { replace: true });
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  // Handle removing item from liked items
  const handlelikeCartremove1 = async (cart_id) => {
    try {
      const api_url = localStorage.getItem('api_url');
      const response = await axios.get(api_url + '/removelickcart/' + cart_id);
      if (response.data.status) {
        window.location.reload(); // Consider a better way to update state or UI
      }
    } catch (error) {
      console.error("Error removing from liked items:", error);
    }
  };

  // Fetch liked items for the current user
  useEffect(() => {
    const fetchCards = async () => {
      try {
        const user_id = localStorage.getItem('admin_id');
        const store_id = localStorage.getItem('store_id');
        const api_url = localStorage.getItem('api_url');

        const response = await axios.post(api_url + '/getstorefoods', {
          user_id: user_id,
          store_id: store_id
        });

        // Set initial total amount for each card
        const foodDetailsWithAmount = response.data.fooddetails.map((food) => ({
          ...food,
          totalAmount: food.price * food.quantity // Calculate initial total amount
        }));

        setCards(foodDetailsWithAmount);
      } catch (error) {
        console.error("Error fetching liked items:", error);
      }
    };

    fetchCards();
  }, []);

  return (
    <div>
      <h2 className="save_for">Foods ({cards.length})</h2>
      <hr className="" />
      {cards.map((product) => (
        <div key={product.product_id}>
          <div className="order-card">
            <div className="product-info">
              <img className="product-image" src={product.product_image} alt={product.product_name} />
              <div className="product-details">
                <p className="product-name"><b>{product.product_name}</b></p>
                <p>{product.brand_name}</p>
                <p className="product-price"> ₹ {product.price}</p>
                <div className="quantity_details">
                  <div className="quantity-controls">
                    <button className="quantity-button" onClick={() => decreaseQuantity(product.product_id, product.quantity, product.price)}>-</button>
                    <span className="quantity">{product.quantity}</span>
                    <button className="quantity-button" onClick={() => increaseQuantity(product.product_id, product.price)}>+</button>
                  </div>
                </div>
                <p className="total-amount">Total: ₹ {product.totalAmount}</p>
                <button className="add-to-cart-button" onClick={() => handleAddToCart(product.cart_id, product.product_id, product.price, product.quantity)}>Add to Cart</button>
              </div>
              <div className="del_days">
                <p> </p>
              </div>
            </div>
          </div>
          <hr className="" />
        </div>
      ))}
      {cards.length === 0 && (
        <p className="emptycart">Foods are empty</p>
      )}
      <br />
    </div>
  );
};

export default Myfoods;
