import React, { useState, useEffect } from "react";
import './Detail_tab.css'; // Import the CSS file for styling
import axios from "axios";
import Productuser_review from "./Productuser_review";

const Detail_tab = () => {
  const [activeTab, setActiveTab] = useState('tab1'); // State to track the active tab
  const [cards, setCards] = useState([]);
  const [cards1, setCards1] = useState([]);



  const handleTabClick = (tab) => {
    setActiveTab(tab); // Update the active tab when a tab is clicked
  };

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const product_id = localStorage.getItem('product_id');
        const api_url = localStorage.getItem('api_url');
        const response = await axios.get(api_url + '/getproduct_detail/'+product_id);
        setCards(response.data.productdetails);
        setCards1(response.data.productattribute);
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };

    fetchCards();
  }, []);

  return (
    <div className="tabs-container">
      <div className="tabs">
        <div
          className={activeTab === 'tab1' ? 'tab active' : 'tab'}
          onClick={() => handleTabClick('tab1')}
        >
          Detail
        </div>
        <div
          className={activeTab === 'tab2' ? 'tab active' : 'tab'}
          onClick={() => handleTabClick('tab2')}
        >
          More Information
        </div>
        <div
          className={activeTab === 'tab3' ? 'tab active' : 'tab'}
          onClick={() => handleTabClick('tab3')}
        >
          Reviews
        </div>
      </div>
      <div className="tab-content1">
  {activeTab === 'tab1' && (
    <div>
      <div className="detail_tab">
        {cards.map((card, index) => (
          <p key={index}><b>{card.spec_key} :</b> <span className="detail_tab1">{card.spec_value}</span></p>
        ))}
        {cards1.length > 0 && (
          <>
            <p><b>Brand :</b> <span className="detail_tab1">{cards1[0].brand}</span></p>
            <p><b>Material :</b> <span className="detail_tab1">{cards1[0].material}</span></p>
            <p><b>Organic Certified :</b> <span className="detail_tab1">{cards1[0].organiccertified}</span></p>
            <p><b>Size :</b> <span className="detail_tab1">{cards1[0].size}</span></p>
            <p><b>Expiry Date :</b> <span className="detail_tab1">{cards1[0].expirydate}</span></p>
          </>
        )}
      </div>
    </div>
  )}
  {activeTab === 'tab2' && (
    <div>
      <p className="more_info">{cards1.length > 0 ? cards1[0].ingredients : ''}</p>
    </div>
  )}
  {activeTab === 'tab3' && (
    <div>
      <Productuser_review />
    </div>
  )}
</div>
    </div>
  );
};

export default Detail_tab;
