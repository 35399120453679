import React from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import Menu from './Menu';

import Prductslide from './Prductslide';
import Catslide from './Catslide';
import Bannerslideshow from './Bannerslideshow';
import Cookies from 'js-cookie';
import "./Prductslide.css";
import Product_rating from './Product_rating';
import {withRouter} from "./withRouter";

// import useCustomBackNavigation from './useCustomBackNavigation';
 

class Home extends React.Component{


  constructor(props) {
    // useCustomBackNavigation();
    super(props);
    this.state = {
      api_url:localStorage.getItem('api_url'),
        data:[],
        coo_product_id:localStorage.getItem('coo_product_id'),
        coo_product_name:localStorage.getItem('coo_product_name'),
        coo_product_image:localStorage.getItem('coo_product_image'),
        coo_price:localStorage.getItem('coo_price'),
        coo_quantity:localStorage.getItem('coo_quantity'),
        prescription:'no',search:'',suc_cls:'',
        errors:  {},openModal : false,pic:'',fileName:'',email:'',email1:'',password:''
        
    };     
    this.handleButtonClick = this.handleButtonClick.bind(this); 
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleModal1 = this.toggleModal1.bind(this);
  }
 

  handleButtonClick (e, id){
    e.preventDefault();
    localStorage.setItem("product_id",id)
    this.props.navigate('/Detail_page');
  };
  toggleModal1 ( id){ 
    
    localStorage.setItem("product_id",id)
    this.props.navigate('/Detail_page');
  };
 
  toggleModal() {
    this.setState(prevState => ({
      openModal: !prevState.openModal
    }));
  }

  componentDidMount() {    
    
    //  if(this.state.coo_product_name !==""){
    //   this.setState(prevState => ({
    //     openModal: !prevState.openModal
    //   }));
    //  }
}
 
//  banner_contents(){
      
//   axios({
//     method: 'get',
//     url: this.state.api_url+'/getBannerContents/',               
    
//   }).then(res => {
//     const data = res.data;      
//     this.setState({                         
//             banner: data.banners,             
//           });
//   });


//  }

      render(){
        return  <div className=""> 
                   { <Header_home /> }              
                        <Menu />
                       <Bannerslideshow/>
                       <br></br>
                       <br></br>
                       <br></br>
                       <br></br>
                       <br></br>
                       <h2 className='mar_place'> The Market Place</h2>
                       <p className="bus_to">is a business-to-business platform based in the United States enabling buyers from around the globe to source products direct from manufacturers, suppliers, exporters and importers.</p>
                     
                       <img className="slide_off" alt="Group" src={require('./img/img_back.png')} />
                        <img className="slide_off1" alt="Group" src={require('./img/img_back1.png')} />
                        <br></br>
                       <br></br>
                       <br></br>
                       <br></br>
                       <br></br>
                        <div className='sell_best_product'>
                        <br></br>
                       <br></br>
                        <h2 className="">BEST SELLER PRODUCTS</h2>
                        <br></br>
                       <br></br>
                        <Prductslide/>
                        <br></br>
                        <br></br>
                        <br></br>
                        </div>
                           
                       
                        <br></br>
                        <br></br>
                        <div className='sell_best_product'>
                          <h2 className="">SHOP BY CATEGORY</h2>
                          <Catslide/>
                          <br></br>
                          <br></br>
                          <br></br>
                          <br></br>
                       </div>
                       <br></br>
                       <br></br>
                       <br></br>
                       <br></br>
                        <div>
                           <img className="final_img" alt="Group" src={require('./img/img_back2.png')} />
                           <br></br>
                       <br></br>
                        </div>

                       <br></br>
                       <br></br>
                       <div className='sell_best_product'>
                       <br></br>
                       <br></br>
                       <h2 className="">TRENDING PRODUCTS</h2>
                       <br></br>
                      
                       <Prductslide/>
                       <br></br>
                       <br></br>
                       <br></br>
                       </div>
                   { <Footer /> }
                   
        </div>

      }
}

export default withRouter(Home);