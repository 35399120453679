import React, { useState, useEffect } from "react";
import './FavoriteCheckbox.css'; // Import the CSS file for styling
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const FavoriteCheckbox = () => {
  const [isFavorite, setIsFavorite] = useState(false); // State to track favorite status
  const [product, setProduct] = useState(null); // State to store the product data
  const [selectedQuantity, setSelectedQuantity] = useState(1); // State to store the selected quantity

  const navigate = useNavigate();

  const handleAddToCart = async (qty) => {
    try {
      const product_id = product._id; // Use product._id instead of cards[0].product_id
      const user_id = localStorage.getItem('admin_id'); // Assuming you have a user_id stored in localStorage
      const quantity = qty;
      const price = calculatePrice(qty);
  
      const api_url = localStorage.getItem('api_url');
      const response = await axios.post(api_url + '/addtocart', {
        product_id,
        user_id,
        quantity,
        price,
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest: [(data, headers) => {
          const params = new URLSearchParams();
          for (const key in data) {
            params.append(key, data[key]);
          }
          return params.toString();
        }],
      });
      const check_res = response.data.status;
  
      if (check_res) {

           // update product quantity 
           const remain_qty = product.qty - qty;

           const api_url = localStorage.getItem('api_url');
      // const response1 = await axios.post(api_url + '/updateproductquantity', {
      //   product_id,
      //   remain_qty,
      // }, {
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded'
      //   },
      //   transformRequest: [(data, headers) => {
      //     const params = new URLSearchParams();
      //     for (const key in data) {
      //       params.append(key, data[key]);
      //     }
      //     return params.toString();
      //   }],
      // });

      const check_res1 = response.data.status;

      if(check_res1){
        alert(response.data.message);
        navigate("/Addcart", { replace: true });
      }

        
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };
  
  const calculatePrice = (qty) => {
    if (product && product.discountAmount) {
      return product.discountAmount * qty;
    }
    return product.price * qty;
  };


  const handleCheckboxChange = async (qty) => {

    try {
      const product_id = product._id; // Use product._id instead of cards[0].product_id
      const user_id = localStorage.getItem('admin_id'); // Assuming you have a user_id stored in localStorage
      const quantity = qty;
      const price = calculatePrice(qty);
  
      const api_url = localStorage.getItem('api_url');
      const response = await axios.post(api_url + '/likecart', {
        product_id,
        user_id,
        quantity,
        price,
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        transformRequest: [(data, headers) => {
          const params = new URLSearchParams();
          for (const key in data) {
            params.append(key, data[key]);
          }
          return params.toString();
        }],
      });
      const check_res = response.data.status;
  
      if (check_res) {
      
        const remain_qty = product.qty - qty;

        const api_url = localStorage.getItem('api_url');
  //  const response3 = await axios.post(api_url + '/updateproductquantity', {
  //    product_id,
  //    remain_qty,
  //  }, {
  //    headers: {
  //      'Content-Type': 'application/x-www-form-urlencoded'
  //    },
  //    transformRequest: [(data, headers) => {
  //      const params = new URLSearchParams();
  //      for (const key in data) {
  //        params.append(key, data[key]);
  //      }
  //      return params.toString();
  //    }],
  //  });

   const check_res3 = response.data.status;



          if(check_res3){
            alert('Cart liked successfully');
            setIsFavorite(!isFavorite);
            navigate("/Likecart", { replace: true });
          }
        
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const product_id = localStorage.getItem('product_id');
        const api_url = localStorage.getItem('api_url');
        const response = await axios.get(api_url + '/admingetProduct_frontend/' + product_id);
        setProduct(response.data.product);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProduct();
  }, []);

  return (
    <div className="favorite-checkbox">
       <select className='count_cart' name="count_cart" id="count_cart" value={selectedQuantity} onChange={(e) => setSelectedQuantity(parseInt(e.target.value))}>
        {[...Array(product?.qty || 1).keys()].map((num) => (
          <option key={num + 1} value={num + 1}>{num + 1}</option>
        ))}
      </select>
      <button className='add_cart' onClick={() => handleAddToCart(selectedQuantity)}> Add to Cart</button>
      <label className="favorite-label">
        <input
          type="checkbox"
          checked={isFavorite}
          onChange={() => handleCheckboxChange(selectedQuantity)}
          className="favorite-checkbox-input"
        />
        <span className="favorite-icon">{isFavorite ? '❤️' : '🤍'}</span>
      </label>
    </div>
  );
};

export default FavoriteCheckbox;
