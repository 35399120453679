import React, { useState } from 'react';
import './style.css';
import Autocomplete from './Autocomplete';
import { useNavigate } from 'react-router-dom';

const Header_home = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  const handleDivClick = () => {
    navigate('/Home');
  };

  const handlelogout = () => {
    localStorage.clear();
    navigate('/Login');
  };

  const handlelike = () => {
    navigate('/Likecart');
  };

  const handlecart = () => {
    navigate('/Addcart');
  };
  
  const handleDivtrack = () => {
    navigate('/Myorders');
  };

  const handleprofile = (id) => {
    setSelectedImage(id);
    navigate('/Profile');
  };

  return (
    <div className="header">
      <div className="row">
        <div className='col-md-4'>
          <p className="welcome-to-market">Welcome To Medcall Online Store !</p>
        </div>
        <div className='col-md-2'>
          <p className="store_loc">Store Location</p>
        </div>
        <div className='col-md-2' onClick={handleDivtrack}>
          <p className="trackorder">Track Order</p>
        </div>
        <div className='col-md-2'>
          <select className='country_name' value="" >
            <option value='91'>India</option>
          </select>
        </div>
        <div className='col-md-2'>
          <select className='language_name' value="" >
            <option value='1'>English</option>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-md-9">
          <p className="pro_logo1"></p>
          <div className='select_product'>
            <div className="auto_all1">
              <select name="select_pro" value="" id="select_pro" className='select_pro'>
                <option value="all">All</option>
              </select>
            </div>
            <div className="auto_search1">
              <Autocomplete />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <img className="heart" alt="Group" title="Like Cart" src={require('./img/heart.png')} onClick={handlelike} />
          <img className="vector" alt="Group" title="Cart" src={require('./img/Vector.png')} onClick={handlecart} />
          <img className={`vector1 ${selectedImage === 'vector1' ? 'selected' : ''}`} alt="Group" title="Profile" onClick={() => handleprofile('vector1')} src={require('./img/Vector1.png')} />
          <img className="logout" alt="Logout" title="Logout" src={require('./img/logout.png')} onClick={handlelogout} />
        </div>
      </div>
    </div>
  );
};

export default Header_home;
